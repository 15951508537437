/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

// below defines your custom color to build a theme palette from
$primary-pallette: (
  50: #00AEBE,
  100: #00AEBE,
  200: #00AEBE,
  300: #00AEBE,
  400: #00AEBE,
  500: #00AEBE, // Generalmente viene utilizzato questo
  600: #00AEBE,
  700: #00AEBE,
  800: #00AEBE,
  900: #00AEBE,
  A100: #00AEBE,
  A200: #00AEBE,
  A400: #00AEBE,
  A700: #00AEBE,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  )
);

$custom-typography: mat.define-typography-config(
  $font-family: 'Roboto, Arial, sans-serif',
);

$primary: mat.define-palette($primary-pallette);
$accent: mat.define-palette($primary-pallette);
$warn: mat.define-palette($primary-pallette);

@include mat.core();

$theme: mat.define-light-theme((
  color: (
    primary: $primary,
    accent: $accent,
    warn: $warn,
    theme-type: light,
  ),
  typography: $custom-typography
));

@include mat.all-component-themes($theme);
//@include angular-material-typography($custom-typography);