/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

:root {
    --white: #f4f3f2;
    --ion-color-primary: #005C7E;//default => #3880ff;
    --ion-color-secondary: #0cd1e4;
    --ion-color-success: #10dc60;
    --ion-color-warning: #E7DA53;//default => #ffce00;
    --ion-color-tertiary: #EEBF7D//default => #7044ff;
    --ion-color-danger: #EE867D;//default => #f14d4f;
    --ion-color-dark: #222428;
    --ion-color-medium: #989aa2;
    --ion-color-light: #f4f5f8;
    --ion-color-base: #f4f5f8;
}

/* Viene in questo modo disabilitato il pull-refresh di chrome */
html, body {
    overscroll-behavior: none;
}
